<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getPMApi} from '@/api/pm'


/**
 * User list component
 */
export default {
  page: {
    title: "PMA List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      pma_list: [],
      title: "PMA List",
      items: [
        {
          text: "PMA",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "Code",
          sortable: true,
        },
        {
          key: "Agent",
          sortable: true,
        },
      
        {
          key: "Address",
          sortable: true,
        },
      ],

      current_landlord : {}
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList(p) {
      getPMApi().pma_list({page : {page : p, page_size: this.perPage}}).then((res)=>{
        this.pma_list = []
        res.data.map((o) =>{
          this.pma_list.push(o)
        })
        this.totalRows = res.page.total;
      })
    },

    pageChange(p) {
      this.queryList(p)
    },
  

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    PMA List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                > 
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="pma_list"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  class="table-check"
                >

                  <template #cell(Code)="data">
                    {{ data.item.code }}
                  </template>
                  <template #cell(Agent)="data"> 
                    {{ data.item.agent.full_name }}
                  </template>
                  <template #cell(Address)="data">
                    {{ data.item.address }}
                  </template>
                  
                  <template #cell(action)>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end"  >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
